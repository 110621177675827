.cabinet-head {
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  z-index: 2;
  .container {
    padding: 0 0 0 30px;
  }
  &__container {
    height: 170px;
    display: flex;
    justify-content: space-between;
  }
  &__section {
    width: 100%;
    &_left {
      align-items: center;
      padding-right: 120px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: auto;
    }
  }
  &__dropdown {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    border: 1px solid #dcdcdc;
    padding: 10px 0;
    border-top: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    opacity: 0;
    &-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &-link {
      display: block;
      white-space: nowrap;
      padding: 7px 40px 7px 20px;
    }
  }
  &__nav-list {
    height: 100%;
    margin: 0;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__nav-item {
    height: 100%;
    display: block;
    position: relative;
    &_payment {
      padding: 0;
      .cabinet-head__nav-link {
        &:before {
          display: none;
        }
      }
      &:hover .cabinet-head__dropdown{
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__nav-link {
    display: block;
    position: relative;
    height: 100%;
    padding: 45px 20px 0 20px;
    transition: 0.3s;
    color: #7a68b0;
    svg {
      display: block;
      margin: 0 auto 20px auto;
      height: 37px;
      width: auto;
    }
    span {
      display: block;
      text-align: center;
    }
    svg path {
      transition: 0.3s;
      width: 100%;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: -1px;
      left: 0;
      background-color: #f98b21;
      transition: transform 0.3s;
      transform: scaleX(0);
    }
    &:hover {
      color: #f98b21;
      &:before {
        transform: scaleX(1);
      }
      svg {
        path {
          stroke: #f98e26 !important;
        }
      }
    }
    &_active {
      color: #f98b21;
      &:before {
        transform: scaleX(1);
      }
      svg {
        path {
          stroke: #f98e26 !important;
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .cabinet-head {
    .container {
      padding: 0 0 0 15px;
    }
    &__section_left {
      padding-right: 80px;
    }
    &__nav-list {
      justify-content: flex-end;
    }
    &__nav-item {
      padding: 0;
    }
  }
}
@media (max-width: 992px) {
  .cabinet-head {
    &__container {
      height: 140px;
    }
    &__section_left {
      padding-right: 20px;
    }
    &__nav-link {
      padding: 30px 15px 0 15px;
    }
  }
}
@media (max-width: 768px) {
  .cabinet-head {
    display: none;
  }
}