@import "./partials/normalize";
@import "./partials/fonts";

$main-font: 15px "Arial", sans-serif;
$main-color: #606465;
//size
$container-width: 1180px;
a {
  color: #8c79c0;
  text-decoration: none;
}
.main-link {
  transition: color 0.3s;
  &:hover {
    color: #f98b21;
  }
}
input,
select {
  border-radius: 0;
  appearance: none;
  height: 43px;
  border: 1px solid #acacac;
  padding: 0 10px;
  &::placeholder {
    color: #adafb1;
  }
  &:focus {
    &::placeholder {
      transition: 0.3s;
      opacity: 0;
    }
  }
}
label {
  padding: 0 0 10px 0;
  display: inline-block;
  color: #b7b7b7;
  font-size: 15px;
  font-weight: 700;
}
body {
  text-rendering: optimizeSpeed;
  font: $main-font;
  min-width: 320px;
  color: $main-color;
  background-color: #f2f3f7;
}
.container {
  max-width: $container-width;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}
.block__container {
  padding: 40px;
  background-color: #fff;
}
.block__title {
  font-size: 22px;
  margin: 0;
  padding-bottom: 25px;
  display: block;
}
.rub {
  font-family: 'PTSans';
}
.page {
  position: relative;
  z-index: 1;
  overflow: hidden;
  &_with-aside {
    padding: 75px 0 50px 0;
    .container {
      display: flex;
    }
    .main {
      width: 100%;
    }
    .aside {
      width: 212px;
      flex-shrink: 0;
      box-sizing: content-box;
      padding-right: 35px;
      flex-shrink: 0;
    }
  }
}
.aside {
  .banner2 {
    padding: 100px 0 0 0;
  }
}
.button {
  height: 43px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  color: #fff;
  border: 0;
  cursor: pointer;
  outline: none;
  &_style1 {
    background-color: #f98b21;
    &:hover {
      background-color: #fa9738;
    }
  }
  &_style2 {
    background-color: #7962a6;
    &:hover {
      background-color: #594680;
    }
  }
  &_style3 {
    background-color: #c4c4c4;
    &:hover {
      background-color: #a8a6a6;
    }
  }
}
.activity-label {
  padding-left: 32px;
  position: relative;
  line-height: 20px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #676a6c;
  }
  &_active {
    color: #79b74d;
    &:before {
      background: #79b74d url('../img/icons/activity-label.svg') no-repeat center center;
      background-size: 16px auto;
    }
  }
  &_disable {
    color: #c71e00;
    span {
      display: block;
      position: absolute;
      left: 0;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      background: #c71e00;
      &:before,
      &:after {
        height: 2px;
        width: 10px;
        content: '';
        display: block;
        position: absolute;
        left: 5px;
        top: 9px;
        background-color: #fff;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
.action-label {
  display: flex;
  color: #c7225b;
  align-items: center;
  span {
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    border-radius: 100%;
    background-color: #c7225b;
    color: #fff;
  }
}
.text {
  margin: 0 0 15px 0;
}

@media all and(max-width: 1180px) {
  .block__container {
    padding: 30px;
  }
}
@media all and(max-width: 992px) {
  .page_with-aside {
    .aside {
      padding-right: 20px;
    }
  }
}
@media all and(max-width: 768px) {
  .page_with-aside {
    padding: 0;
    .container {
      padding: 0;
    }
    .aside {
      display: none;
    }
    .main {
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
    }
  }
  .block__title {
    padding-bottom: 15px;
  }
}
@media all and(max-width: 576px) {
  .block__container {
    padding: 20px;
  }
}

@import "./partials/header";
@import "./partials/footer";
@import './partials/banner';
@import './partials/cabinet-head';
@import './partials/desktop-nav';
@import './partials/personal-number';
@import './partials/mobile-head';
@import './partials/popup';